// General
import "./account-signup.scss";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// Services
import { useAuthSignupMutation } from "../../../services/data.service";
import { emailRegex } from "../../../services/regex.service";
// Static Data
import route from "../../../const/route";
// i18next
import { useTranslation } from "react-i18next";
// Environment
import environment from "../../../environment/environment";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateEmail, updatePassword } from "../../../redux/store/signup";
import {
  updateApiToken,
  updateUserProfile,
} from "../../../redux/store/account";
// react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";
// Material UI
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const AccountSignup = () => {
  // General variables
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const recaptchaRef = useRef();

  // API variables
  const [
    authSignup,
    {
      data: authSignupData,
      error: authSignupErrorData,
      isLoading: authSignupLoading,
      isSuccess: authSignupSuccess,
      isError: authSignupError,
    },
  ] = useAuthSignupMutation();

  // Redux variables
  const queryString = useSelector((state) => state.public.queryString);
  const email = useSelector((state) => state.signup.email);
  const password = useSelector((state) => state.signup.password);
  const gender = useSelector((state) => state.signup.gender);
  const lookingFor = useSelector((state) => state.signup.lookingFor);
  const wantToBe = useSelector((state) => state.signup.wantToBe);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const navigate = useNavigate();

  // Lifecycle | Check for update | Auth Signup API Response
  useEffect(() => {
    if (authSignupLoading) {
    } else if (authSignupSuccess) {
      dispatch(updateApiToken(authSignupData?.data?.legacy_token));

      setTimeout(() => {
        navigate(route.redirect.path + queryString);
      }, 2000);
    } else if (authSignupError) {
      onOpenSnackbar();
    }
  }, [authSignupLoading, authSignupSuccess, authSignupError]);

  // Event Handlers | Button
  const onSignup = async () => {
    const queryStringObj = Object.fromEntries(new URLSearchParams(queryString));
    const referer = document.referrer;
    queryStringObj.reference_url = referer || "";

    // Google Recaptcha Token
    await recaptchaRef.current
      .executeAsync()
      .then((token) => {
        const obj = {
          auth_method: "email_password",
          email,
          password,
          gender,
          wanttobe: wantToBe,
          gre_token: token,
          utm_data: queryString !== "" ? queryStringObj : [],
        };
        authSignup(obj);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  // Event Handlers | MUI
  const onEmailChange = (e) => {
    dispatch(updateEmail(e.target.value));
  };
  const onEmailFocus = (e) => {
    setEmailFocus(true);
  };
  const onPasswordChange = (e) => {
    dispatch(updatePassword(e.target.value));
  };
  const onPasswordFocus = (e) => {
    setPasswordFocus(true);
  };
  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const onCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Utility Functions
  const isFormValid = () => {
    let valid = false;
    if (emailValid && passwordValid) {
      valid = true;
    }

    return valid;
  };
  const emailValidation = () => {
    let valid = true;
    let error = false;

    const regex = emailRegex;

    if (!emailFocus) {
      valid = false;
      error = false;
    } else if (emailFocus && email === "") {
      setEmailErrorMessage(t("signup.email_address_is_required"));
      valid = false;
      error = true;
    } else if (!regex.test(email)) {
      setEmailErrorMessage(t("signup.email_address_is_not_valid"));
      valid = false;
      error = true;
    } else {
      setEmailErrorMessage("");
      valid = true;
      error = false;
    }

    setEmailValid(valid);
    setEmailError(error);
  };
  const passwordValidation = () => {
    let valid = true;
    let error = false;

    if (!passwordFocus) {
      valid = false;
      error = false;
    } else if (passwordFocus && password === "") {
      setPasswordErrorMessage(t("signup.password_is_required"));
      valid = false;
      error = true;
    } else if (passwordFocus && password.length < 6) {
      setPasswordErrorMessage(
        t("signup.password_must_be_n_characters", { n: 6 })
      );
      valid = false;
      error = true;
    } else {
      setPasswordErrorMessage("");
      valid = true;
      error = false;
    }

    setPasswordValid(valid);
    setPasswordError(error);
  };

  // Check for email and password validation
  useEffect(() => {
    emailValidation();
    passwordValidation();
  }, [
    email,
    password,
    emailFocus,
    passwordFocus,
    emailErrorMessage,
    passwordErrorMessage,
  ]);

  return (
    <Box
      id="account-signup-page"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div className="max-width-container">
        <div className="create-account-label">
          {t("signup.create_new_account")}
        </div>
        <div className="email-form-field-container">
          <TextField
            className="email-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={email}
            error={emailError}
            onChange={onEmailChange}
            label={t("login.email_address")}
            placeholder={t("login.email_address")}
            helperText={emailErrorMessage}
            variant="standard"
            onFocus={onEmailFocus}
          />
        </div>

        <div className="password-form-field-container">
          <TextField
            className="password-form-field"
            sx={{
              "& .MuiInput-underline:after": { borderBottomColor: "#710D0D" },
              "& label.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
            }}
            required
            value={password}
            error={passwordError}
            onChange={onPasswordChange}
            label={t("login.password")}
            type="password"
            placeholder={t("login.password")}
            helperText={passwordErrorMessage}
            variant="standard"
            onFocus={onPasswordFocus}
          />
        </div>

        <div className="recaptcha-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env["REACT_APP_RECAPTCHA_V3_SITE_KEY"]}
            theme="light"
          />
        </div>

        <Button
          className={`signup-button ${isFormValid() ? "" : "disabled-button"}`}
          variant="text"
          onClick={onSignup}
          disabled={!isFormValid()}
        >
          {authSignupLoading ? (
            <CircularProgress className="button-spinner" size={24} />
          ) : (
            t("signup.sign_up")
          )}
        </Button>

        <div className="terms-and-condition">
          {t("signup.signup_agree")}{" "}
          <span className="terms-container">
            {/* Open dialog */}
            <Link className="terms" to="/terms">
              {t("signup.signup_terms")}
            </Link>
          </span>{" "}
          &{" "}
          <span className="privacy-policy">
            {/* Open dialog */}
            <Link className="privacy-policy" to="/privacy">
              {t("signup.signup_policy")}
            </Link>
          </span>
          . {t("signup.signup_desc")}
        </div>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        message={authSignupErrorData?.data?.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key={"bottom" + "center"}
      ></Snackbar>
    </Box>
  );
};

export default AccountSignup;
