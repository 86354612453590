import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-locize-backend"; // Waiting to move to locize
import EnglishJson from "./language/en.json";
import TraditionalChineseJson from "./language/zh-tw.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  // .use(Backend) // Waiting to move to locize
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: TraditionalChineseJson,
      },
      zh: {
        translation: TraditionalChineseJson,
      },
    },
    // backend: {
    //   projectId: "",
    //   apiKey: "",
    // },
  });

export default i18n;
